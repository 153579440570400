<template>
  <!-- Authors Table Card -->
  <div style="position: relative">
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">
              {{ lang == "uz" ? "Laboratoriyalar" : "Лаборатории" }}
            </h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-radio-group
              v-model="authorsHeaderBtns"
              size="small"
              style="margin-right: 25px"
            >
            </a-radio-group>
            <a-button type="primary" @click="showModal()">
              {{ lang == "uz" ? "Foydalanuvchi qo'shish" : "Добавить" }}
            </a-button>
          </a-col>
        </a-row>
      </template>
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="{ pageSize: 25 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <!-- <template slot="photo" slot-scope="photo">
				<div class="table-avatar-info">
					<a-avatar shape="square" :src="photo" />
				</div>
			</template> -->

        <!-- <template slot="avatar" slot-scope="avatar">
          <div class="table-avatar-info">
            <a-avatar shape="square" src="images/patient.png" />
          </div>
        </template> -->

        <template slot="userId" slot-scope="userId">
          <div class="author-info">
            <h6 class="m-0">{{ userId }}</h6>
          </div>
        </template>

        <template slot="Number" slot-scope="Number">
          <div class="author-info">
            <h6 class="m-0">{{ Number }}</h6>
          </div>
        </template>

        <template slot="specialization" slot-scope="specialization">
          <div class="author-info">
            <h6 class="m-0">{{ specialization }}</h6>
          </div>
        </template>

        <template slot="editBtn" slot-scope="row">
          <div class="author-info" style="display: flex">
            <a-button
              type="link"
              :data-id="row.key"
              @click="getDataEdit(row)"
              class="btn-edit"
            >
              {{ lang == "uz" ? "Tahrirlash" : "Редактировать" }}
            </a-button>
            <a-button
              type="link"
              :data-id="row.key"
              @click="openDeleteModal(row)"
              class="btn-delete"
            >
              {{ lang == "uz" ? "O'chirish" : "Удалить" }}
            </a-button>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="visible"
      :title="
        lang == 'uz' ? 'Foydalanuvchi qo\'shish' : 'Добавить пользователя'
      "
      @ok="handleOk"
    >
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "RAQAMI / NOMI :" : "НОМЕР / ИМЯ :" }}
      </div>
      <a-input
        type="text"
        :placeholder="lang == 'uz' ? 'RAQAMI / NOMI' : 'НОМЕР / ИМЯ :'"
        v-model="number"
        required
      />
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "MUTAXASSISLIK :" : "СПЕЦИАЛИЗАЦИЯ :" }}
      </div>
      <a-input
        type="text"
        :placeholder="lang == 'uz' ? 'MUTAXASSISLIK' : 'СПЕЦИАЛИЗАЦИЯ'"
        v-model="specialization"
        required
      />
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "LOGIN :" : "ЛОГИН :" }}
      </div>
      <a-input
        type="text"
        :placeholder="lang == 'uz' ? 'Username' : 'Имя пользователя'"
        v-model="username"
        required
      />
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "PAROl :" : "ПАРОЛЬ :" }}
      </div>
      <a-input
        type="text"
        :placeholder="lang == 'uz' ? 'Password' : 'Пароль'"
        v-model="password"
        required
      />
    </a-modal>
    <a-modal
      v-model="editVisible"
      :title="
        lang == 'uz' ? 'Foydalanuvchini tahrirlash' : 'Изменить пользователя'
      "
      @ok="handleEdit"
    >
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "RAQAMI / NOMI :" : "НОМЕР / ИМЯ :" }}
      </div>
      <a-input
        type="text"
        placeholder="RAQAMI / NOMI"
        v-model="edit_number"
        required
      />
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        {{ lang == "uz" ? "MUTAXASSISLIK :" : "СПЕЦИАЛИЗАЦИЯ :" }}
      </div>
      <a-input
        type="text"
        :placeholder="lang == 'uz' ? 'MUTAXASSISLIK' : 'СПЕЦИАЛИЗАЦИЯ'"
        v-model="edit_specialization"
        required
      />
      <!-- <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        LOGIN :
      </div>
      <a-input
        type="text"
        placeholder="Username"
        v-model="edit_username"
        required
      />
      <div style="font-weight: 700; margin-top: 15px; margin-bottom: 5px">
        PAROl :
      </div>
      <a-input
        type="text"
        placeholder="Password"
        v-model="edit_password"
        required
      /> -->
    </a-modal>
    <a-modal v-model="deleteVisible" @ok="handleDelete">
      <p style="font-weight: 700; font-size: 16px; color: red">
        {{
          lang == "uz"
            ? "Foydalanuvchini o'chirishni xohlaysizmi ?"
            : "Вы хотите удалить пользователя ?"
        }}
      </p>
    </a-modal>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
// import RegisterPatient from "@/views/Register_patient.vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  // components: {
  //   RegisterPatient,
  // },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      lang: null,
      authorsHeaderBtns: "all",
      visible: false,
      deleteVisible: false,
      editVisible: false,
      number: "",
      specialization: "",
      username: "",
      password: "",
      edit_number: "",
      edit_specialization: "",
      edit_username: "",
      edit_password: "",
      deleteId: null,
      editUserId: null,
      register_link: "",
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk() {
      if (this.username.length < 4 || this.password.length < 4) {
        this.$toast.error(
          this.lang == "uz"
            ? "Login yoki parol 4 ta belgidan kam bo'lmasin !"
            : "Логин или пароль не должны быть меньше 4 символов !",
          {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          }
        );
        return false;
      }
      if (this.number && this.specialization) {
        axios
          .post("/api/auth/register", {
            username: this.username,
            password: this.password,
            role: "LABORATORY",
          })
          .then((res) => {
            let user_id = res.data.id;
            axios
              .post("/api/laboratories", {
                number: this.number,
                specialization: this.specialization,
                userId: Number(user_id),
              })
              .then(() => {
                this.$toast.success(
                  this.lang == "uz"
                    ? "Foydalanuvchi qo'shildi !"
                    : "Пользователь добавлен !",
                  {
                    position: "top-right",
                    timeout: 5000,
                    draggablePercent: 0.6,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                  }
                );
                this.visible = false;
                this.number = "";
                this.specialization = "";
                this.username = "";
                this.password = "";
                this.$emit("refreshData");
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error(
              this.lang == "uz"
                ? "Iltimos boshqa login/parol yozing !"
                : "Пожалуйста, введите другой логин/пароль!",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          });
      } else {
        this.$toast.error(
          this.lang == "uz"
            ? "Barcha maydonlarni to'ldiring !"
            : "Заполните все поля !",
          {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          }
        );
      }
    },
    getDataEdit(obj) {
      this.register_link = obj._links.self.href;
      this.editUserId = obj.userId;
      this.edit_number = obj.number;
      this.edit_specialization = obj.specialization;
      axios.get(obj._links.user.href).then((res) => {
        this.edit_username = res.data.username;
        this.editVisible = true;
      });
    },
    handleEdit() {
      axios
        .put(this.register_link, {
          number: this.edit_number,
          specialization: this.edit_specialization,
          userId: this.editUserId,
        })
        .then(() => {
          // axios
          //   .put("/api/users/" + this.editUserId, {
          //     username: this.edit_username,
          //     password: this.edit_password,
          //     role: "LABORATORY",
          //   })
          // .then(() => {
          this.editVisible = false;
          this.$emit("refreshData");
          this.$toast.success(
            this.lang == "uz"
              ? "Foydalanuvchi tahrirlandi !"
              : "Пользователь отредактирован !",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
          // });
        });
    },
    openDeleteModal(obj) {
      this.deleteVisible = true;
      this.editUserId = obj.userId;
      let userLink = obj._links.self.href;
      this.deleteId = userLink.substring(
        userLink.lastIndexOf("/") + 1,
        userLink.length
      );
    },
    handleDelete() {
      axios.delete("/api/laboratories/" + this.deleteId).then(() => {
        axios.delete("/api/users/" + this.editUserId).then(() => {
          this.$toast.success(
            this.lang == "uz"
              ? "Foydalanuvchi o'chirildi !"
              : "Пользователь удален !",
            {
              position: "top-right",
              timeout: 5000,
              draggablePercent: 0.6,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
            }
          );
          this.deleteVisible = false;
          this.deleteId = null;
          this.editUserId = null;
          this.$emit("refreshData");
        });
      });
    },
  },
};
</script>

<style scoped>
.btn-edit {
  color: #28a745;
  border: 1px solid #28a745;
  padding: 10px 20px !important;
}
.btn-edit:hover {
  background-color: #28a745;
  color: #fff;
  transition: 0.2s ease;
}
.btn-delete {
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  padding: 10px 20px !important;
  margin-left: 10px;
}
.btn-delete:hover {
  background-color: #ff4d4f;
  color: #fff;
  transition: 0.2s ease;
}
/* .form_section {
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(3px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */
/* .sex_color {
	color:red;
} */
</style>